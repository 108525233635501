// Constants
export const constants = {
  CHANGE: 'LOCALE/CHANGE'
};

// Actions
export const actions = {
  change: locale => ({type: constants.CHANGE, locale})
};

// Selectors
export const select = state => state.locale;

// Initial state
export const initialState = 'ru';

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case constants.CHANGE:
      return action.locale;

    default:
      return state;
  }
};
