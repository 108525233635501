// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-screens-apartments-apartment-index-js": () => import("/var/www/p14-static/src/screens/apartments/Apartment/index.js" /* webpackChunkName: "component---src-screens-apartments-apartment-index-js" */),
  "component---src-pages-404-js": () => import("/var/www/p14-static/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartments-js": () => import("/var/www/p14-static/src/pages/apartments.js" /* webpackChunkName: "component---src-pages-apartments-js" */),
  "component---src-pages-architecture-js": () => import("/var/www/p14-static/src/pages/architecture.js" /* webpackChunkName: "component---src-pages-architecture-js" */),
  "component---src-pages-building-js": () => import("/var/www/p14-static/src/pages/building.js" /* webpackChunkName: "component---src-pages-building-js" */),
  "component---src-pages-design-js": () => import("/var/www/p14-static/src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-documents-index-js": () => import("/var/www/p14-static/src/pages/documents/index.js" /* webpackChunkName: "component---src-pages-documents-index-js" */),
  "component---src-pages-documents-logs-js": () => import("/var/www/p14-static/src/pages/documents/logs.js" /* webpackChunkName: "component---src-pages-documents-logs-js" */),
  "component---src-pages-hamovniki-index-js": () => import("/var/www/p14-static/src/pages/hamovniki/index.js" /* webpackChunkName: "component---src-pages-hamovniki-index-js" */),
  "component---src-pages-hamovniki-map-js": () => import("/var/www/p14-static/src/pages/hamovniki/map.js" /* webpackChunkName: "component---src-pages-hamovniki-map-js" */),
  "component---src-pages-index-js": () => import("/var/www/p14-static/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifestyle-js": () => import("/var/www/p14-static/src/pages/lifestyle.js" /* webpackChunkName: "component---src-pages-lifestyle-js" */),
  "component---src-pages-policy-js": () => import("/var/www/p14-static/src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-team-js": () => import("/var/www/p14-static/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-technical-characteristics-js": () => import("/var/www/p14-static/src/pages/technical-characteristics.js" /* webpackChunkName: "component---src-pages-technical-characteristics-js" */)
}

